import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from "@angular/core";
import * as Highcharts from 'highcharts';
import PatternFill from "highcharts/modules/pattern-fill";
import Exporting from "highcharts/modules/exporting";
import Annotations from "highcharts/modules/annotations";
import Debugger from "highcharts/modules/debugger";

if (typeof Highcharts === 'object') {
    PatternFill(Highcharts);
    Exporting(Highcharts);
    Annotations(Highcharts);
    Debugger(Highcharts);
}
import {H} from "../../shared/helpers/H";
import {SiteService} from "../../shared/services/site.service";
import {Plot, PlotUnit} from "../../shared/models/models";
import {BaseComponent} from "../../shared/BaseComponent";
import {AppService} from "../../shared/services/app.service";
import {AxisPlotLinesOptions, Chart} from "highcharts";
import {K} from "../../shared/models/K";
import {OptimiseChart} from "../../shared/helpers/OptimiseChart";
import {BehaviorSubject, from, Observable, Subscription} from "rxjs";
import {Optimise} from "../../shared/helpers/Optimise";
import {concatMap} from "rxjs/operators";
import {Pdm} from "../../shared/models/Pdm";
import {PdmSignature} from "../../shared/models/PdmSignature";
import {PdmYearModel} from "../../shared/models/PdmYearConfig";

@Component({
    selector: 'statmens-cassure',
    template: `
        <div fxLayout="column">
            <div fxLayout="row">
                <div fxFlex>
                    <highcharts-chart [Highcharts]="Highcharts" [runOutsideAngular]="true"
                                      [options]="chartOptions" [(update)]="updateFlag"
                                      (chartInstance)="onChartInstance($event)"
                                      style="width: 620px; height: 380px; display: block;margin: 5px">
                    </highcharts-chart>
                </div>
                <table fxFlex="560px" class="table-values" *ngIf="tempSignature&&tempSignature.points&&showTable">
                    <tr (click)="myapp.showConsole(tempSignature)">
                        <td></td>
                        <td>ind</td>
                        <td>X</td>
                        <td>Y</td>
                        <td>Tg</td>
                        <td>Td</td>
                        <td>tTot</td>
                        <td>penteG</td>
                        <td>consG</td>
                        <td>penteD</td>
                        <td>consD</td>
                        <td>ecart</td>
                        <td>conn</td>
                    </tr>
                    <tr *ngFor="let pt of tempSignature.points;let i=index;">
                        <td>{{ i + 1 }}</td>
                        <td (click)="tempSignature.sortBy('index')">{{ pt.index }}</td>
                        <td [class.inf]="pt.x<pt.connexion"
                            (click)="tempSignature.sortBy('x')">{{ pt.x|number:'1.2-2' }}
                        </td>
                        <td (click)="tempSignature.sortBy('y')">{{ pt.y|number:'1.2-2' }}</td>
                        <td>{{ pt.tGauche|number:'1.2-2' }}</td>
                        <td>{{ pt.tDroite|number:'1.2-2' }}</td>
                        <td (click)="tempSignature.sortBy('tTotal')">{{ pt.tTotal|number:'1.2-2' }}</td>
                        <td>{{ pt.leftSlope|number:'1.2-2' }}</td>
                        <td>{{ pt.leftConstant|number:'1.1-1' }}</td>
                        <td>{{ pt.rightSlope|number:'1.1-1' }}</td>
                        <td>{{ pt.rightConstant|number:'1.2-2' }}</td>
                        <td (click)="tempSignature.sortBy('ecart')">{{ pt.ecart|number:'1.2-2' }}</td>
                        <td (click)="tempSignature.sortBy('connexion')">{{ pt.connexion|number:'1.2-2' }}</td>
                    </tr>
                </table>
                <div fxFlex="360px">
                    <div fxLayout="column">
                        <div class="config-panel" *ngIf="tempSignature">
                            <h2> Valeur: {{ tempSignature.cassure|number }}</h2>
                            <h3 style="margin: 10px 0 4px 0">Options </h3>
                            <mat-checkbox (change)="updateSignature()" class="mr-16" [(ngModel)]="tempSignature.sd">
                                Saut discret
                            </mat-checkbox>
                            <mat-checkbox (change)="updateSignature()" [(ngModel)]="tempSignature.isManual">
                                Saisie manuelle
                            </mat-checkbox>

                            <div class="p-4 cassure-input" *ngIf="tempSignature.isManual">
                                <input matInput placeholder="Cassure manuelle" type="number" step="0.1"
                                       [(ngModel)]="tempSignature.manual" (keyup.enter)="updateSignature()">
                            </div>
                            <h3 style="margin: 10px 0 4px 0">Plat </h3>
                            <mat-radio-group [(ngModel)]="tempSignature.flat" (change)="updateSignature()">
                                <mat-radio-button class="mr-8" [value]="'NONE'"> Normal</mat-radio-button>
                                <mat-radio-button class="mr-8" [value]="'LEFT'"> Gauche</mat-radio-button>
                                <mat-radio-button class="mr-8" [value]="'RIGHT'"> Droite</mat-radio-button>
                            </mat-radio-group>

                            <ng-container *ngIf="!plot.isCost()">
                                <h3 style="margin: 10px 0 4px 0">Unité Y</h3>
                                <mat-radio-group [value]="plot.plotUnit.unit_plot" (change)="setUnitFactor($event.value)">
                                    <mat-radio-button class="mr-8" [value]="'K'"> Kilo</mat-radio-button>
                                    <mat-radio-button class="mr-8" [value]="'M'"> Mega</mat-radio-button>
                                    <mat-radio-button class="mr-8" [value]="'G'"> Giga</mat-radio-button>
                                </mat-radio-group>
                            </ng-container>

                            <h3 style="margin: 10px 0 4px 0">Type de cassure</h3>
                            <mat-radio-group [(ngModel)]="tempSignature.type" (change)="updateSignature()">
                                <mat-radio-button class="mr-8" [value]="'CHAUD'"> Chaud</mat-radio-button>
                                <mat-radio-button class="mr-8" [value]="'FROID'"> Froid</mat-radio-button>
                                <mat-radio-button class="mr-8" [value]="'MIXTE'"> Mixte</mat-radio-button>
                            </mat-radio-group>
                            <hr>
                            <button mat-raised-button color="primary" class="btn-xs-25 mr-4" (click)="updateSignature(true)">
                                Sauvegarder
                            </button>
                            <button mat-raised-button color="primary" class="btn-xs-25 mr-4" (click)="tempSignature.calc()">
                                Redraw
                            </button>
                            <button mat-raised-button color="primary" class="btn-xs-25 mr-4" (click)="showTable=!showTable">
                                Table {{ showTable }}
                            </button>
                            <div fxLayout="row" *ngIf="tempSignature&&tempSignature.calc_cache">
                                <button *ngFor="let kv of tempSignature.calc_cache|keyvalue;"
                                        (click)="drawSignatureByIndex(kv.key)">draw {{ kv.key }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `,

    styleUrls: ['../../shared/components/shared-styles/shared.scss']
})
export class StatmensCassureComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {
    @Input('plot') plot: Plot;
    @Input('pdm') dataPdm: Pdm;
    @Input('year') year: number;

    showTable = false;

    chart: Chart;
    errors: any[] = [];
    Highcharts: typeof Highcharts = Highcharts;
    public chartOptions: Highcharts.Options = {...K.chartOptions};
    updateFlag = false;
    signature: any; // for SIGNATURE
    cassure = 0;    // for SIGNATURE

    pointsForSignatureCalc = [];
    optimiseChart: OptimiseChart;
    public dataChangeListener: BehaviorSubject<number>;
    public tempSignature: PdmSignature = null;

    subsStatMensConfig: Subscription;

    constructor(public myapp: AppService, public site: SiteService,) {
        super();
        this.dataChangeListener = new BehaviorSubject<number>(0);
    }

    ngOnInit() {
        if (this.subsStatMensConfig) this.subsStatMensConfig.unsubscribe();
        this.subsStatMensConfig = this.site.siteSpecificEventTriggerer.subscribe(event => {
            //  if (event === SiteService.STATMENS_CONFIG_RELOADED) {
            if (event === SiteService.PDMS_DATA_POPULATED && this.site.refYear) {
                if (this.dataPdm && this.chart) {
                    this.renderChart();
                }
            }
        });
        this.chartOptions.chart.width = 580;
        this.chartOptions.chart.height = 380;
        this.chartOptions.legend.enabled = false;
        this.chartOptions.xAxis['title'].text = this.getXAxisLabel();
        //this.chartOptions.plotOptions.series.color = '#000000';

        this.site.loadStatmensConfigs();
    }

    bootstrap(debugCalledBy = "") {
        this.site.loadSitePdmsYearlyConfigs();
        this.site.selectReportYearAndInitPdmYearlyConfig(this.site.year);
        console.info('bootstrap: ' + debugCalledBy, this.plot, Highcharts.charts);
        this.errors = [];
        this.initStatMensConfig();

        this.optimiseChart = new OptimiseChart(
            this.plot,
            this.site.selectedPdm,
            this.site.selectedPdmConfigForRefYear,
            this.site.dataSummaryMap);

        this.optimiseChart.applyForCassure(this);
        // this.chartClear();
        if (this.chart)
            this.renderChart();
    }

    getSignaturePoints() {
        this.pointsForSignatureCalc = this.site.selectedPdm
            .getMensualData(this.site.year)
            .map((dataForMonth, i) => {
                const ptX = (dataForMonth.vars_meteo ? dataForMonth.vars_meteo['m1'] : -1) || 0;
                //  const ptY = 30 * dataForMonth.ve / dataForMonth.days * this.plot.getUnitFactor();
                const ptY = dataForMonth.ve;// * this.plot.getUnitFactor();
                // const ptY = dataForMonth.ve;// * this.plot.getUnitFactor();
                return {x: ptX, y: ptY, category: ptX, custom: (i + 1), date: dataForMonth.date, index: i + 1};
                // return {x: ptX, y: item.val };
            });
        this.tempSignature = new PdmSignature(this.pointsForSignatureCalc);
    }

    setUnitFactor(newValue) {
        const plotUnit = new PlotUnit(this.plot.getKey(), this.site.selectedPdm.fluid);
        plotUnit.setUnit(newValue);
        this.site.saveSelectedPdmConfig('units', {key: this.plot.getKey(), plotUnit}, true);
    }

    initStatMensConfig() {
        if (this.site.selectedPdmConfigForRefYear) {
            this.plot.plotUnit = this.site.selectedPdmConfigForRefYear.getUnitForPlot(this.plot.getKey());
        }
    }

    renderChart() {
        this.getSignaturePoints();
        if (!this.dataPdm) {
            console.log('StatmensCassureComponent:renderChart', 'dataPdm EMPTY');
            return;
        }
        if (!this.chart) {
            console.log('StatmensCassureComponent:renderChart', 'chart null');
            return;
        }

        if (this.site.selectedPdmConfigForRefYear && this.site.selectedPdmConfigForRefYear.signature_year) {
            //console.error("StatmensCassureComponent:renderChart:", this.site.selectedPdmConfigForYear);
            //   this.tempSignature = new PdmSignature(this.site.selectedPdmConfigForRefYea¬r.signature_year);
        }

        this.drawSignature();
        this.drawMainPointsAndCassure();
    }

    drawMainPointsAndCassure() {
        this.chartUpdateSerie(0, this.pointsForSignatureCalc, 'Update Signature-cassure');
        this.tempSignature.cassure = Number(this.tempSignature.cassure);
        const line = {
            id: 'cassure',
            label: 'Cassure:' + this.tempSignature.cassure.toFixed(2),
            dashStyle: 'longdashdot',
            color: '#FF0000',
            width: 1,
            value: this.tempSignature.cassure
        } as any;

        if (this.chart.xAxis && this.chart.xAxis.length > 0) {
            this.chart.xAxis[0].userOptions.tickInterval = undefined;
            this.chart.xAxis[0].userOptions.tickAmount = undefined;
            this.chart.xAxis[0].userOptions.tickPixelInterval = 80;
            this.chart.xAxis[0].removePlotLine('cassure');
            this.chart.xAxis[0].addPlotLine(line as AxisPlotLinesOptions);
            //this.chartOptions.yAxis['min'] = 400000;
            //this.chartOptions.yAxis['max'] = 800000;
        }
    }

    drawSignature() {
        const serieLines = this.tempSignature.plot_data.map(pt => {
            pt['y'] = pt['y'];// * this.plot.getUnitFactor();
            return pt;
        });
        //this.chartOptions.yAxis['max'] = this.tempSignature.maxY;
        if (this.chart && this.chart.series && this.chart.series[1]) {
            this.chart.series[1].setData(serieLines, true);
        }
        console.log("drawSignature ", serieLines, this.tempSignature);

    }

    drawSignatureByIndex(index) {
        const serieLines = this.tempSignature.calc_cache[index].plot.map(pt => {
            pt['y'] = pt['y'];// * this.plot.getUnitFactor();
            return pt;
        });
        this.tempSignature.cassure = this.tempSignature.calc_cache[index].pt.connexion;
        this.drawMainPointsAndCassure();
        //this.chartOptions.yAxis['max'] = this.tempSignature.maxY;
        if (this.chart && this.chart.series && this.chart.series[1]) {
            this.chart.series[1].setData(serieLines, true);
        }
        console.log("drawSignature ", this.tempSignature.calc_cache[index]);
        console.log("drawSignature ", serieLines, this.tempSignature);

    }

    updateSignature(save = false) {
        this.tempSignature.calc();
        this.drawMainPointsAndCassure();
        this.drawSignature();
        if (save) this.site.saveSelectedPdmConfig('signature', this.tempSignature, true);
    }

    /*









     */

    getXAxisLabel() {
        let retVal = "";
        let weatherStation = this.site.clientSite.weather;
        if (weatherStation.includes('.')) {
            const chunks = weatherStation.split(".");
            weatherStation = chunks[0];
        }
        retVal = "Température (" + weatherStation + ")";
        return retVal;
    }

    // called from OptimiseChart
    public chartUpdate() {
        this.optimiseChart.setTitle();

        //this.chartOptions.plotOptions.series.color = '#00ff00';
        if (this.chart) this.chart.update(this.chartOptions, false, false);
    }

    chartUpdateSerie(index, data, debugInfos = "") {
        if (!this.chart) {
            return;
        }
        if (this.chart.series && this.chart.series[index]) {
            this.chart.xAxis[0].setCategories(undefined);
            this.chart.series[index].setData(data, false, false);
        } else {
            console.log("SERIE PAS TROUVöE", index, data);
        }
    }

    onChartInstance(chart) {
        this.chart = chart;
        this.renderChart();
    }

    ngAfterViewInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log("ngOnChanges---------- ", changes);
        this.bootstrap('ngOnChanges');
    }

    raiseError(message, year: number, severity, type = 'data') {
        this.errors.push({message, severity, ts: H.unixTs(), year, type});
    }
}
