import {LegendOptions, LinearGradientColorObject, PatternOptionsObject, PlotLineOptions} from "highcharts";
import {H} from "../helpers/H";

export class K {
    public static lineWidthBase = 0.5;
    public static regFields = ['p', 'i', 'k', 'd', 't', 'c'];
    public static fluids = ['ELEC', 'FROID', 'CHALEUR', 'EAU'];
    public static weekDays = ['', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    public static months = ['jan', 'fév', 'mar', 'avr', 'mai', 'jun', 'jul', 'aoû', 'sep', 'oct', 'nov', 'déc'];
    public static monthsIndexToNumString = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
    public static weedDayToIndex = {
        sun: 0, mon: 1, tue: 2, wed: 3, thu: 4, fri: 5, sat: 6
    };
    public static colorArray = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];

    public static inMailerProtoHebdoText = "Bonjour,\n" +
        "\n" +
        "Le nouveau protocole hebdomadaire commenté de la semaine courante est disponible.\n" +
        "\n" +
        "Meilleurs messages et salutations.\n" +
        "\n";
    public static inMailerBmensText = "Bonjour,\n" +
        "\n" +
        "Le nouveau bilan mensuel est disponible.\n" +
        "\n" +
        "Meilleurs messages et salutations.\n" +
        "\n";
    public static VisualTypes = {
        SIGNATURE: 'SIGNATURE',
        BAR: 'BAR',
        CURVE: 'CURVE',
        TABLE: 'TABLE',
        PIE: 'PIE',
    };

    public static chartOptions: Highcharts.Options = {
        chart: {
            displayErrors: false,
            plotBackgroundColor: '#ffffff',
            plotShadow: false,
            borderColor: '#000000',
            borderWidth: 0,
            plotBorderWidth: K.lineWidthBase,
            reflow: false,
            width: 950,
            height: 550,
            style: {
                fontFamily: "Roboto",
                fontSize: "14px"
            }
        },
        lang: {
            thousandsSep: "'",
            numericSymbols: null,
        },
        title: {useHTML: false, align: 'left', x: 20},
        tooltip: {
            shared: false,
            backgroundColor: '#FFFFFFFF',
            borderWidth: 1,
            borderColor: '#000'
        },

        exporting: {
            enabled: false,
            showTable: false,
            width: 2400,
        },
        colorAxis: {
            lineColor: '#FF0000'
        },
        xAxis: {
            floor: 0,
            labels: {
                rotation: 0,
                formatter: (s) => {
                    if (s.axis.userOptions && s.axis.userOptions.id && s.axis.userOptions.id.includes("months")) {
                        if (K.months[s.value])
                            return H.ucfirst(K.months[s.value]);
                        else return s.value;
                    }
                    return s.value;//K.formatNumber(s.value, 0);
                },
                style: {
                    color: '#000000',
                    fontSize: '12px',
                }
            },
            title: {
                margin: 5,
                style: {
                    color: '#000000',
                    fontSize: '15px',
                    fontWeight: 'bold',
                }
            }, tickPosition: 'outside', tickColor: '#000000',
            tickWidth: K.lineWidthBase,
            tickPixelInterval: 60,
            gridLineWidth: K.lineWidthBase,
            //   softMin: 0,
            /*

        categories: [],
        opposite: false,

        tickInterval: null,


        gridLineWidth: 0.5,
        gridLineColor: "#cccccc",

        startOnTick: false,
        endOnTick: true,*/
            lineColor: '#333333',
            lineWidth: K.lineWidthBase
        },
        yAxis: {
            softMin: 0,
            startOnTick: true,
            tickPixelInterval: 60,
            minPadding: 0.1,
            endOnTick: true,
            tickWidth: K.lineWidthBase,
            gridLineWidth: 0.5,
            gridLineColor: "#cccccc",
            tickPosition: 'outside',
            tickColor: '#000000',
            title: {
                text: 's',
                margin: 5,
                style: {
                    color: '#000000',
                    fontSize: '13px',
                    fontWeight: 'bold',
                }
            },
            labels: {
                formatter: (s) => {
                    return K.formatNumber(s.value, 0);
                },
                style: {
                    fontSize: '12px',
                    color: '#000000'
                }
            },
            lineColor: '#000000',
            lineWidth: K.lineWidthBase
        },
        colors: ['#c58eff', '#01ff00', '#ff0000', '#ffff00', '#1aadce', '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
        credits: {enabled: false,},
        series: [],
        plotOptions: {
            series: {
                stickyTracking: false,

                dataLabels: {
                    shadow: false,
                    position: "center",
                    animation: false,
                },
                marker: {
                    radius: 5,
                    lineWidth: 1,
                    lineColor: '#000000'
                },
            },
            column: {
                animation: false,
                groupPadding: 0.05,
                stacking: "normal",
                borderWidth: 1,
                grouping: true,
                shadow: false,
                visible: true,
                edgeWidth: 5,
                borderColor: '#000000'
            },
            line: {
                lineWidth: K.lineWidthBase,
            }
        },
        legend: {
            squareSymbol: false,
            symbolRadius: 0,
            symbolWidth: 40,
            borderWidth: K.lineWidthBase,
            layout: 'vertical',
            align: 'right',
            padding: 10,
            itemStyle: {fontSize: '10px', fontWeight: "normal", lineHeight: '8px'},
            verticalAlign: 'middle',
            itemMarginTop: 0,
            itemMarginBottom: 0
        } as LegendOptions
    };
    public static varExplMap = {
        'DAY_COS': "Cos num jour",
        'DAY_NUM': "Numéro du jour",
        'DAY_CNT': "Nombre de jours",
        'M_1': "Temp moyenne",
        'M_2': "Humidité",
        'M_3': "Ensoleillement",
        'DJ_EP': "Degré j EP",
        'DJ_SI': "Degré jours SI",
    };

    public static markers = ['square', 'circle', 'triangle', 'diamond', 'square'];
    public static defaultConfig = {
        // hide plotly logo
        displayLogo: false,
        // disable native (plotly.js) toolbar
        displayModeBar: false,
        editable: false,

        edits: {
            annotationPosition: true,
            annotationTail: true,
            annotationText: false,
            shapePosition: true,
            // annotationPosition: the main anchor of the annotation, which is the
            // text (if no arrow) or the arrow (which drags the whole thing leaving
            // the arrow length & direction unchanged)
            // just for annotations with arrows, change the length  and direction of the arrow
            axisTitleText: false,
            colorbarPosition: false,
            colorbarTitleText: false,
            legendPosition: false,
            // edit the trace name fields from the legend
            legendText: false,
            // the global `layout.title`
            titleText: false,
            autoSize: true,
            width: 800,
        },
        locale: 'fr-CH',
    };
    public static lineType = [
        'Solid',
        'Solid',
        'LongDashDot',
        'Dash',
        'ShortDash',
        'ShortDashDot',
        'ShortDashDotDot',
        'Dot',
        'Dash',
        'LongDash',
        'DashDot',
        'LongDashDot',
        'LongDashDotDot'];
    public static FluidsLabel = {
        // ['ELEC', 'FROID', 'CHALEUR', 'EAU'];
        ELEC: 'Elec.',//Electricité
        FROID: 'Froid',
        CHALEUR: 'Chaleur',
        EAU: 'Eau',
    }
    public static EnergyAgents = {
        EMPTY: 'EMPTY',
        FROID: 'FROID',
        SOLAR_ELEC: 'SOLAR_ELEC',
        SOLAR_THERMAL: 'SOLAR_THERMAL',
        WATER: 'WATER',
        ELECTRICITY: 'ELECTRICITY',
        HEATING_OIL: 'HEATING_OIL',
        GAZ_COMBUSTIBLE: 'GAZ_COMBUSTIBLE',
        COAL: 'COAL',
        FOSSIL_FUEL: 'FOSSIL_FUEL',
        WOOD: 'WOOD',
        BIO_GAZ: 'BIO_GAZ',
        THERMAL_RELEASE: 'THERMAL_RELEASE',
        CAD: 'CAD',
        CAD_CVE: 'CAD_CVE',
        GEOTHERMAL: 'GEOTHERMAL',
    };
    public static EnergyAgentsPriceKWh = {
        EMPTY: 0,
        FROID: 0,
        WATER: 0,
        SOLAR_ELEC: 0,
        SOLAR_THERMAL: 0,
        ELECTRICITY: 100,
        HEATING_OIL: 100,
        GAZ_COMBUSTIBLE: 100,
        COAL: 100,
        FOSSIL_FUEL: 100,
        WOOD: 100,
        BIO_GAZ: 100,
        THERMAL_RELEASE: 'THERMAL_RELEASE',
        CAD: 100,
        CAD_CVE: 100,
        GEOTHERMAL: 100,
    };
    public static EnergyAgentsFactors = {
        EMPTY: 0,
        FROID: 0,
        SOLAR_ELEC: 0,
        SOLAR_THERMAL: 0,
        WATER: 1100,
        ELECTRICITY: 2,
        HEATING_OIL: 1,
        GAZ_COMBUSTIBLE: 1,
        COAL: 1.4,
        FOSSIL_FUEL: 1,
        WOOD: 0.1,
        BIO_GAZ: 0.1,
        THERMAL_RELEASE: 0.1,
        CAD: 0.5,
        CAD_CVE: 0.5,
        GEOTHERMAL: 0,
    }

    public static EnergyAgentsDisplayNames = {
        NOAGENT: 'EAU',
        FROID: 'Froid',
        SOLAR_ELEC: 'Solaire photovoltaique',
        SOLAR_THERMAL: 'Solaire thermique',
        EMPTY: 'Vide',
        WATER: 'Eau',
        ELECTRICITY: 'Electricité',
        HEATING_OIL: 'Mazout',
        GAZ_COMBUSTIBLE: 'Gaz',
        COAL: 'Charbon',
        FOSSIL_FUEL: 'Déchets combustible fossiles',
        WOOD: 'Bois',
        BIO_GAZ: 'Biogaz, gaz d’épuration',
        THERMAL_RELEASE: 'Rejets thermiques',
        CAD: 'CAD',
        CAD_CVE: 'CAD/CVE',
        GEOTHERMAL: 'Géothermie',
    };
    public static EnergyAgentsDisplayNamesFull = {
        EMPTY: 'Vide',
        FROID: 'Froid',
        SOLAR_ELEC: 'Solaire photovoltaique',
        SOLAR_THERMAL: 'Solaire thermique',
        WATER: 'Eau',
        ELECTRICITY: 'Electricité',
        HEATING_OIL: 'Huile de chauffage (EL, moyenne et lourde)',
        GAZ_COMBUSTIBLE: 'Gaz combustibles (gaz naturel, butane, propane, etc.)',
        COAL: 'Charbon (Houille et lignite)',
        FOSSIL_FUEL: 'Déchets combustible fossiles',
        WOOD: 'Bois',
        BIO_GAZ: 'Biogaz, gaz d’épuration',
        THERMAL_RELEASE: 'Rejets thermiques (industrie, usine d’épuration)',
        CAD: 'Chaleur de proximité et à distance',
        CAD_CVE: 'Chaleur de proximité et à distance à partir de CVE',
        GEOTHERMAL: 'Géothermie, chaleur de l’environnement, chaleur solaire',
    };


    public static Status = {
        PROPOSAL: 'PROPOSAL',
        ACCEPTED: 'ACCEPTED',
        REFUSED: 'REFUSED',
        PROGRESS: 'PROGRESS',
        COMPLETED: 'COMPLETED',
    }
    public static StatusValue = {
        REFUSED: 0,
        PROPOSAL: 1,
        ACCEPTED: 2,
        PROGRESS: 3,
        COMPLETED: 4,
    }
    public static StatusDisplayName = {
        PROPOSAL: 'Proposé',
        ACCEPTED: 'Accepté',
        REFUSED: 'Refusé',
        PROGRESS: 'En cours',
        COMPLETED: 'Terminé',
    }
    public static StatusIcon = {
        PROPOSAL: 'assistant',
        ACCEPTED: 'check',
        REFUSED: 'priority_high',
        PROGRESS: 'trending_up',
        COMPLETED: 'done_all',
    }
    public static StatusColor = {
        PROPOSAL: '#BDBDBD',
        ACCEPTED: '#0172FF',
        REFUSED: '#F44336',
        PROGRESS: '#FF9800',
        COMPLETED: '#01D58A',
    }

    public static Priority = {
        LOW: 'LOW',
        MEDIUM: 'MEDIUM',
        HIGH: 'HIGH',
    }
    public static PriorityValue = {
        LOW: 1,
        MEDIUM: 2,
        HIGH: 3,
    }
    public static PriorityIcon = {
        LOW: 'low_priority',
        MEDIUM: 'error',
        HIGH: 'priority_high',
    }
    public static PriorityColor = {
        LOW: '#BDBDBD',
        MEDIUM: '#FFBA03',
        HIGH: '#FF3565',
    };
    public static PriorityDisplayName = {
        LOW: 'Faible',
        MEDIUM: 'Normale',
        HIGH: 'Haute',
    };

    public static Units = {
        PETROL: 'petrol',
        ENERGY: 'energy',
        POWER: 'power',
        VOLUME: 'volume',
        CURRENCY: 'currency',
        WEIGHT: 'weight',
        NO_UNIT: 'no_unit',
    };
    public static unitShort = {
        petrol: 'ℓ',
        energy: 'MWh',
        power: 'MW',
        volume: 'm³',
        weight: 't',
        no_unit: '',
    };
    public static unitDisplayName = {
        petrol: 'Mazout [ℓ]',
        energy: 'Énergie [MWh]',
        power: 'Pointe [MW]',
        volume: 'Volume [m³]',
        weight: 'Masse [tonnes]',
        no_unit: 'Sans Unité',
    };
    public static metricUnit = {
        petrol: 'ℓ',
        energy: 'kWh',
        power: 'kW',
        volume: 'm³',
        weight: 't',
        no_unit: '',
    };
    public static TypeEngagementLabel = {
        COC: 'Convention d\'objectifs cantonale (COC)',
        ACE: 'Analyse de consommation énergétique (ACE)',
        RAO: 'Rapport annuel d\'optimisation (RAO)',
    };

    public static Roles = {
        ADMIN: 4,
        SUPERUSER: 3,
        AUTHENTICATED: 1,
    }
    public static RoleDisplayName = {
        1: 'Utilisateur',
        3: 'Super Utilisateur',
        4: 'Administateur',
    };
    public static VCardLabels = {
        address: 'Addresse',
        company: 'Entreprise',
        npa_loc: 'NPA/Localité',
        tel_mobile: 'Tél. mobile',
        tel_fixed: 'Tél. fixe',
    };

    public static Responsibilities = {
        ENERGY_MANAGER: 'ENERGY_MANAGER',
        DOMAIN_REFERENT: 'DOMAIN_REFERENT',
        CLIENT_SANS_SUIVI: 'CLIENT_SANS_SUIVI',
        TECHNICAL_MANAGER: 'TECHNICAL_MANAGER',
        NONE: 'NONE',
        ONLY_PROT_HEBDO: 'ONLY_PROT_HEBDO',
        ONLY_ACTIONS: 'ONLY_ACTIONS',
    }
    public static InfoFieldDisplayName = {
        year: 'Année de référence',
        startYear: 'Année de début du suivi',
        aLattentionDe: "A l'attention de",
        raison_social: 'Raison sociale de l\'entreprise',
        type_engagement: 'Type d\'engagement',
        no_dossier: 'Numéro dossier',
        no_ide: 'N° IDE',
        code_noga: 'Code(s) NOGA',
        no_egid: 'N°EGID(s)',
        nb_sites: 'Nombre de sites',
        address_contact: 'Adresse du/des sites(s)',
        referent_interne: 'Personne de référence en interne',
        referent_externe: 'Spécialiste externe',
    }
    public static domainResponsability = {
        ENERGY_MANAGER: 4,
        DOMAIN_REFERENT: 3,
        CLIENT_SANS_SUIVI: 3.1,
        TECHNICAL_MANAGER: 2,
        ONLY_PROT_HEBDO: 1,
        ONLY_ACTIONS: 1.1,
        NONE: 0,
    }
    public static displayDomainResponsability = {
        4: 'Gestionnaire Énergétique',
        3: 'Référent Bâtiment',
        3.1: 'Client sans suivi',
        2: 'Responsable Technique',
        1: 'Droit au protocol hebdo',
        1.1: 'Droit à la liste d\'actions',
        0: 'Aucune Responsabilité',
    }

    public static AccessPages = {
        DASHBOARD: ['BILAN_CONSO', 'BILAN_LAST', 'ACTION_SUMMARY'],
        ACTIONS: ['CREATE', 'EDIT_TEXT', 'EDIT_ECONOMY', 'COMMENT', 'UPLOAD', 'ASSIGN_USER'],
        PROTOHEBDO: ['COMMENT', 'COMMENT_REPLY', 'SHARE', 'DEFAULT_COMMENTOR', 'DEFAULT_RECIPIENT'],
        DOCUMENTS: ['UPLOAD'],
        SUIVI: ['EDIT', 'SUBMIT', 'VALIDATE'],
        BMENS: ['COMMENT', 'SHARE', 'DEFAULT_RECIPIENT'],
        STATMENS: ['PDM_EDITOR', 'GLOBAL_CONFIG'],
    };

    public static routesConstants = {
        "/site-home": "DASHBOARD",
        "/proto-hebdo": "PROTOHEBDO",
        "/actions": "ACTIONS",
        "/bmens": "BMENS",
        "/docs": "DOCUMENTS",
        "/suivi": "SUIVI",
        "/pdm-editor": "STATMENS",
        "/global-data": "STATMENS",
        "/users": "ADMIN"
    };
    public static routesForAdmin = [
        "/pdm-editor",
        "/global-data",
        "/users"
    ];

    public static routesForSuperUser = [
        "/pdm-editor",
        "/global-data",
        "/users"
    ];
    public static apeCategs = [
        'Action organisatoire',
        'Air comprimé',
        'Autre équipement électrique',
        'Autres',
        'Chauffage',
        'Climatisation',
        'Eau',
        'Eau chaude sanitaire',
        'Eclairage',
        'Enveloppe thermique',
        'Equipement électrique',
        "Monitoring / Management de l'énergie",
        'Optimisation MCR',
        'Procédés',
        'Système chaud',
        'Système froid',
        'Ventilation'
    ];

    public static getDefaultCostUnits(type) {
        switch (type) {
            case  K.Units.PETROL:
                return 'Fr./ℓ';
            case  K.Units.ENERGY:
                return 'c./kWh';
            case K.Units.POWER:
                return 'Fr./kW';
            case K.Units.VOLUME:
                return 'Fr./m³';
            case K.Units.NO_UNIT:
                return 'Fr./e0';
            case K.Units.WEIGHT:
                return 'Fr./kg';
            default:
                throw new Error(`Unknown type ${type} for Cost Field`);
        }
    }

    public static formatNumber(number, decimals = 2) {
        number = number.toFixed(2) + '';
        let x = number.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        let rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + "'" + '$2');
        }
        if (x2.toString().length > decimals) x2 = x2.substr(0, decimals);
        return x1 + x2;
    }

}
