import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef} from '@angular/core';
import {AppService} from "../../../shared/services/app.service";
import {ApiService} from "../../../shared/services/api.service";
import {Overlay} from "@angular/cdk/overlay";
import {SiteService} from "../../../shared/services/site.service";
import {egretAnimations} from "../../../shared/animations/egret-animations";
import {BaseComponent} from "../../../shared/BaseComponent";
import {Sniffer} from "../../../shared/models/Sniffer";
import {H} from "../../../shared/helpers/H";
import {PdmDataRow} from "../../../shared/models/PdmDataRow";
import {PdmRegDef} from "../../../shared/models/PdmRegDef";
import {Optimise} from "../../../shared/helpers/Optimise";
import * as MathJS from "mathjs";
import moment from "moment";
import {SiteReleveDelay} from "../../../shared/models/models";
import {Pdm} from "../../../shared/models/Pdm";
import {clone} from "mathjs";

@Component({
    selector: 'pdm-importer',
    template: `
        <div class="flex-container full-width " fxLayout="row">
            <div fxLayout="row " class="numeric full-width pt-16" style="font-size: 14px" fxFlexAlign="start start">

                <mat-card class="p-4 m-4" fxLayout="column" fxFlex="50">
                    <h3 class="bloc-h3">Selection des pdm template</h3>
                    <div fxLayout="column">
                        <mat-form-field class="p-8">
                            <mat-label>Choix du pdm</mat-label>
                            <mat-select (selectionChange)="selectPdm($event.value)">
                                <mat-option [value]="pdm" *ngFor="let pdm of pdms">
                                    <span fxFlex><b>{{pdm.ref}}</b>|{{pdm.fluid}}-> {{pdm.label}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <mat-card-footer>
                        <mat-progress-bar
                                *ngIf="foreignRegs&&regsCreated&&foreignRegs.length"
                                style="height: 30px"
                                [color]="'primary'"
                                [mode]="'determinate'"
                                [value]="100*regsCreated.length/foreignRegs.length">
                        </mat-progress-bar>
                    </mat-card-footer>
                </mat-card>
                <mat-card class="p-4 m-4" fxLayout="column" fxFlex="50" fxLayoutAlign="space-between none">
                    <h3 class="bloc-h3 pl-16">Régistres à importer</h3>
                    <div fxLayout="column">
                        <mat-list>
                            <mat-list-item *ngFor="let reg of foreignRegs">
                                <span fxFlex><b>{{reg.type}}</b> | [{{reg.disp_order}}/{{reg.num}}] --> {{reg.label}} </span>
                                <span *ngIf="reg.type === 'SYNCED'">| <i>Non importable</i></span>
                            </mat-list-item>
                        </mat-list>
                    </div>
                    <mat-card-footer>
                        <div fxLayout="row">
                            <span fxFlex></span>
                            <button mat-raised-button class="btn-xs" color="primary" (click)="cloneRegs()">Importer</button>
                        </div>
                    </mat-card-footer>
                </mat-card>
            </div>
        </div>
    `,
    styleUrls: ['./shared.component.scss'],
    animations: egretAnimations
})
export class PdmImportComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {
    @Input('pdm') pdm: Pdm;
    @Input('update') update: number;
    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();

    pdms: Pdm[] = [];
    foreignRegs: PdmRegDef[] = [];
    regsCreated: PdmRegDef[] = [];

    get importableRegs() {
        const regs = this.foreignRegs.filter(r => r.type !== "SYNCED");
        return regs.length;
    }

    constructor(public myapp: AppService, public site: SiteService) {
        super();
    }

    ngOnInit(): void {
        this.site.api.getPdms("all").subscribe(resp => {
            this.pdms = [];
            resp.body.forEach(rawPdm => {
                const tempPdm = new Pdm(rawPdm);
                if (!tempPdm.is_vpdm)
                    this.pdms.push(tempPdm);
            });
        });
    }

    selectPdm(pdm: Pdm) {
        // console.log("PdmImportComponent:Select pdm", pdm);
        this.site.api.getPdmRegs(pdm.uid).subscribe(resp => {
            this.foreignRegs = [];
            resp.body.forEach(rawReg => {
                this.foreignRegs.push(new PdmRegDef(rawReg));
            });
            this.foreignRegs = this.foreignRegs.sort((a, b) => a.disp_order - b.disp_order);

        });
    }

//SELECT * FROM `tb_pdms_regs` WHERE `uid_pdm` LIKE 'elc0jks27099' ORDER BY `uid_client` DESC
    cloneRegs() {
        this.foreignRegs = this.foreignRegs.sort((a, b) => a.disp_order - b.disp_order);
        this.foreignRegs = this.foreignRegs.filter(r => r.type !== "SYNCED");
        let regNum = 0;
        this.foreignRegs.forEach(reg => {
            regNum++;
            let cloned = JSON.parse(JSON.stringify(reg));
            let regObj = new PdmRegDef(cloned);
            regObj.cloned_from = regObj.uid;
            regObj.num = regNum;
            regObj.disp_order = regNum;
            regObj.uid = this.pdm.uid + "_reg" + regObj.num;
            regObj.uid_pdm = this.pdm.uid;
            regObj.uid_client = this.pdm.uid_client;
            regObj.uid_site = this.pdm.uid_site;
            regObj.fluid = this.pdm.fluid;
            regObj.ts_updated = 0;
            regObj.ts_created = H.unixTs();
            const regToSave = this.fixBooleanToInt(regObj);
            regObj = null;
            cloned = null;
            this.site.api.savePdmReg(regToSave, true).subscribe(resp => {
                this.regsCreated.push(new PdmRegDef(resp.body));
                console.log("Saved Reg", resp, this.regsCreated.length, this.foreignRegs.length);
                if (this.regsCreated.length === this.foreignRegs.length) this.cb.emit({arg: 'PDM_CLONAGE_DONE'});
            });

        });
    }

    fixBooleanToInt(obj) {
        Object.keys(obj).forEach(key => {
            if (key.includes("is_") && obj[key] !== undefined) {
                if (String(obj[key]) === "true") obj[key] = 1;
                if (String(obj[key]) === "false") obj[key] = 0;
            }
        });
        return obj;
    }

    /*
    lifecycle hooks
     */
    ngOnChanges(changes: SimpleChanges) {


    }

    ngAfterViewInit() {

    }

    ngOnDestroy() {
    }
}
