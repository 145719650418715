import {
    Chart,
    chart,
    ColorString,
    DashStyleValue, PatternObject,
    PatternOptionsObject, PlotLineOptions, ResponsiveOptions,
    SeriesOptionsType,
    SeriesTooltipOptionsObject,
    SVGAttributes,
    TitleOptions,
    XAxisOptions,
    YAxisOptions
} from "highcharts";
import {H} from "./H";
import {Plot} from "../models/models";
import {K} from "../models/K";
import {StatmensPlotComponent} from "../../views/subs/StatmensPlotCpnt";
import {StatmensCassureComponent} from "../../views/subs/StatmensCassureCpnt";
import * as Buffer from "buffer";
import {PdmYearConfig, PdmYearModel} from "../models/PdmYearConfig";
import {PdmDataSummary} from "../models/PdmDataSummary";
import {Pdm} from "../models/Pdm";


export class OptimiseChart {
    public static SHOW_IN_RAPPORT_MENS = "RAPPORT_MENSUEL";
    colorArray = ['#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
        '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];
    // private colors = ['#ffff00', '#cc99ff', '#00ccff', '#00ff00', '#ff0000'];
    private colors = ['#ff0000', '#00ff00', '#00ccff', '#cc99ff', '#ffff00'];
    chartOptions: Highcharts.Options;
    plot: Plot;
    // pdm: PdmManager;
    historyCount: number;
    _mainUnit: string;

    _series: SeriesOptionsType[];
    _xAxis: XAxisOptions;
    _yAxis: YAxisOptions;
    _Title: TitleOptions;
    reportYear: number;
    refYear: number;
    title: string;
    titleShort: string;

    chart: Chart;
    pdm: Pdm;
    pdmYearConfig: PdmYearConfig;
    dataSummaryMap: Map<string, PdmDataSummary>;
    showedIn: string;
    hasCategories: boolean = false;

    constructor(plot: Plot, pdm: Pdm, statMensConfig: PdmYearConfig, dataSumMap: Map<string, PdmDataSummary>, showedIn = null) {
        this.dataSummaryMap = dataSumMap;
        this.plot = plot;
        this.pdm = pdm;
        this.showedIn = showedIn;
        this.reportYear = plot.yearReport;
        this.refYear = statMensConfig.year;
        this._mainUnit = (this.plot.plotUnit.unit_plot + "w").toUpperCase() + "h";
        if (this.plot.isPointe()) this._mainUnit = (this.plot.plotUnit.unit_plot + "w").toUpperCase();
        if (this.plot.isCost()) this._mainUnit = "Fr. HTVA";
        if (this.plot.isReactive()) this._mainUnit = "kVArh";

        //  if (this.pdmManager.getFluid() !== "EAU") this._mainUnit += "Wh";
        this.historyCount = plot.yearCountHistory;

        this.chartOptions = K.chartOptions;
        this.pdmYearConfig = statMensConfig;
       // if (this.pdmYearConfig && this.pdmYearConfig.model_year_ref)
       //     this.pdmYearConfig.model_year_ref = new PdmYearModel(this.pdmYearConfig.model_year_ref);
        this._xAxis = this.chartOptions.xAxis as XAxisOptions;
        this._yAxis = this.chartOptions.yAxis as YAxisOptions;
        this._Title = this.chartOptions.title as TitleOptions;
        this._series = this.chartOptions.series;// as TitleOptions;
        this.setTitle();

    }

    isShowingInRapportMensuel() {
        return this.showedIn === "RAPPORT_MENSUEL";
    }

    setTitle() {
        //  const yearRef = this.pdm.getRefYear(this.plot.yearReport);
        this._Title.text = '';
        this._xAxis.floor = 0;
        this._yAxis.softMax = undefined;
        this._xAxis['categories'] = undefined;
        //   this._mainUnit = this.pdm.getTotUnit();
        delete this._xAxis['tickAmount'];
        delete this._xAxis['categories'];
        delete this._xAxis['tickInterval'];
        delete this._xAxis['tickPixelInterval'];
        this.hasCategories = false;
        // DEFAULT
        this._xAxis.gridLineWidth = K.lineWidthBase;
        this.chartOptions.legend.squareSymbol = false;
        this.chartOptions.legend.symbolWidth = 40;
        this.chartOptions.chart.plotBorderWidth = 0;
        this._yAxis['title'].text = this._mainUnit;
        this._xAxis['title'].text = this.getXAxisLabel();


        if (this.plot.plotType === "column") {
            this._xAxis.gridLineWidth = 0;
            this.chartOptions.legend.squareSymbol = true;
            this.chartOptions.legend.symbolWidth = undefined;
        }
        if (this.plot.plotType === "column" || this.plot.section === Plot.SECTION_ANNUAL) {

        }
        const dataSummaryForYear = this.dataSummaryMap.get(this.plot.yearReport.toString());
        //categorized plots
        if (this.plot.plotType === "column" || this.plot.section === Plot.SECTION_QUALITY) {
            this._xAxis.tickmarkPlacement = "between";
            this._xAxis.title.text = "Mois";
            this._xAxis.tickAmount = 12;
            this._xAxis.tickInterval = 1;
            this._xAxis.categories = K.months.map(i => H.ucfirst(i));
            this.hasCategories = true;
            this._xAxis.minTickInterval = 1;
            this._xAxis.startOnTick = false;
            this._xAxis.showLastLabel = true;
            this.chartOptions.plotOptions.column.borderWidth = 10;
        }

        // cumul specials
        if (this.plot.cumul) {
            delete this._xAxis['categories'];
            this._xAxis['categories'] = undefined;
            this.hasCategories = false;
            this._xAxis['title'].text = "Jours cumulés";
            this._xAxis['tickInterval'] = 30;
            this._xAxis.tickmarkPlacement = undefined
        }
        if (this.plot.section === Plot.SECTION_SIGNATURE) {
            this._xAxis['tickAmount'] = undefined;
            this._xAxis['categories'] = undefined;
            this.hasCategories = false;
            this._xAxis.tickmarkPlacement = "on";
            this._xAxis.tickInterval = null;
            this._xAxis.minTickInterval = 2;
            this._xAxis.startOnTick = true;
            this._xAxis.endOnTick = true;
            this._xAxis.floor = undefined;
            this._xAxis.tickPixelInterval = 50;
            this._yAxis.tickPixelInterval = 50;
            this.chartOptions.xAxis = this._xAxis;
            this.chartOptions.chart.plotBorderWidth = 2;
            this.chartOptions.chart.plotBorderColor = '#000000';
        }
        if (this.plot.section === Plot.SECTION_SIGNATURE_FINDER) {
            this._xAxis['tickPixelInterval'] = 80;
        }
        if (this.plot.section === Plot.SECTION_QUALITY) {
            this.chartOptions.chart.plotBorderWidth = 2;
            this.chartOptions.chart.plotBorderColor = '#000000';
            this._yAxis.softMax = dataSummaryForYear ? dataSummaryForYear.ve1_d_last * 1.15 * this.plot.getUnitFactor() : undefined;
        }
        if (this.plot.section === Plot.SECTION_DERNIERE) {
            this.hasCategories = true;
            // this._xAxis['title'].text = "Jours cumulés";
            this._xAxis['tickInterval'] = 30;
            this._xAxis.gridLineWidth = 0;
            this._yAxis.tickPixelInterval = 60;
            this._yAxis.softMax = dataSummaryForYear ? dataSummaryForYear.ve1_d_cumul * 1.2 * this.plot.getUnitFactor() : undefined;
        }

        if (!this.isShowingInRapportMensuel())
            this.createSeries();
    }

    createSeries() {
        this._series = [];
        if (!this.plot) return;

        if (this.plot.section === Plot.SECTION_QUALITY || this.plot.section === Plot.SECTION_DERNIERE) {
            const serRealData = this.getSimulation(true);
            this._series.push(serRealData);
            const serSimul = this.getSimulation(false);
            this._series.push(serSimul);
        }
        if (this.plot.section === Plot.SECTION_ANNUAL) {
            const serSimul0 = this.getAnnualSerie(0);
            //const serSimul1 = this.getAnnualSerie(1);
            this._series.push(serSimul0);
            // this._series.push(serSimul1);
        }
        if (this.plot.section === Plot.SECTION_BASIC) {
            if (this.pdm.getIsHaveMultipleSeries() && this.plot.isMain()) {
                for (let i = 0; i < this.historyCount * 2; i++) {
                    let SerieNum = 1;
                    if (i >= this.historyCount) SerieNum = 2;
                    const ser = this.getSerie(i, this._series.length + 1, true, SerieNum, 2);
                    this._series.push(ser);
                }
            } else {
                for (let i = 0; i < this.historyCount; i++) {
                    const ser = this.getSerie(i, this._series.length + 1, true, 1, 2);
                    this._series.push(ser);
                }
            }
            // console.log("createSeries() ended: ", this._series);
        }

        if (this.plot.section === Plot.SECTION_SIGNATURE_FINDER) {
            const ser1 = this.getSerie(0, this._series.length + 1, true);
            this._series.push(ser1);
            const ser2 = this.getSignature(1, 'both', this._series.length + 1, false);
            this._series.push(ser2);
        }
        if (this.plot.section === Plot.SECTION_SIGNATURE) {
            for (let i = 0; i < this.historyCount; i++) {
                const ser = this.getSerie(i, this._series.length + 1, false);
                this._series.push(ser);
            }
            for (let i = 0; i < this.historyCount; i++) {
                const ser = this.getSignature(i, 'BOTH', this._series.length + 1, true);
                this._series.push(ser);
            }
        }
        // this.chartOptions.series = this._series;
        // console.log("CreateSeries", this.plot, this._series);
    }

    getAnnualSerie(serieIndex: number): SeriesOptionsType {
        let year = this.plot.yearReport - this.historyCount + serieIndex + 1;
        const s = {
            animation: {
                duration: 600,
                easing: "in"
            },
            shadow: false,
            color: '#ffff00',
            index: 0,
            id: year + this.plot.section + this.plot.id,
            showInLegend: false,
            name: "Serie: ",
            type: this.plot.plotType,
            data: [],
            stack: year,
        } as SeriesOptionsType;
        if (s.type === "column") {
            s.borderWidth = 1;
            s.borderColor = "#000000";
            s.tooltip = {
                headerFormat: "{series.name}",
                pointFormat: '<br>Année: {point.x}, {point.y:.2f}  {point.name} ' + this._mainUnit,
            };
            s.color = {
                pattern: this.getPattern(4)
            } as PatternObject;
        }

        return s;
    }

    getSerie(serieIndex: number, serieGlobalIndex: number, showInLegend = true, serieNum = 1, serieTot = 1): SeriesOptionsType {
        let year = this.plot.yearReport - this.historyCount + serieIndex + 1;
        let assetIndex = this.historyCount - serieIndex - 1;
        //console.log("creating series type : " + this.plot.plotType, serieNum, serieIndex, "/" + serieGlobalIndex);
        if (serieIndex >= this.historyCount) {
            year -= this.historyCount;
            assetIndex += this.historyCount;
        }

        const s = {
            animation: {
                duration: 600,
                easing: "in"
            },
            shadow: false,
            color: assetIndex === 4 ? '#000000' : this.colors[assetIndex],
            index: serieGlobalIndex,
            id: year + this.plot.section + serieNum + "-" + serieGlobalIndex,
            showInLegend,
            // name: "Serie: " + serieNum + " " + year + " G:" + serieGlobalIndex,
            type: this.plot.plotType,
            data: [],
            stack: year,
            //lineWidth: serieIndex === (this._series.length - 1) && this.plot.cumul ? 3 : 2
        } as SeriesOptionsType;


        if (s.type === "line") {
            s.tooltip = {
                useHTML: true,
                headerFormat: "{series.name}",
                pointFormat: '<br>Jour: {point.x}, {point.y:.0f} ' + this._mainUnit,
            } as SeriesTooltipOptionsObject;
            s.lineWidth = serieIndex === (this._series.length - 1) ? 5 : 2;
            s.dashStyle = K.lineType[assetIndex] as DashStyleValue;
            s.marker = {
                fillColor: this.colors[assetIndex],
                lineColor: '#000000',
                width: 50,
                enabled: true,
                lineWidth: assetIndex === 4 ? 1 : 0,
                symbol: K.markers[assetIndex],
                radius: 6
            };
            if (assetIndex === 0) {
                s.marker = {
                    fillColor: '#ffffff',
                    lineColor: '#ff0000',
                    width: 50,
                    enabled: true,
                    lineWidth: 3,
                    symbol: 'square',
                    radius: 4
                };
            }
        }

        if (s.type === "scatter") {
            // if (this.plot.section === Plot.SECTION_SIGNATURE_FINDER)   s.name = this.titleShort + " " + Number(this.refYear - this.historyCount + serieIndex + 1);// + " - " + assetIndex;
            s.marker = {
                fillColor: this.colors[assetIndex],
                symbol: K.markers[assetIndex],
                lineWidth: serieIndex === 0 && this.historyCount === 5 ? 1 : 0,
                radius: 5
            };
            s.dataLabels = {
                useHTML: false,
                inside: false,
                enabled: true,
                position: 'right',
                style: {
                    fontWeight: 'normal'
                },
                x: 15, y: 10,
                format: '[{point.custom:.0f}]', // no decimal
            };
            s.lineWidth = 0;
            s.dashStyle = K.lineType[assetIndex] as DashStyleValue;
            s.tooltip = {
                useHTML: true,
                //headerFormat: "x: {point.x:.1f}, y: {point.y:.1f} , date: {point.custom:.} ",
                pointFormat: "x: {point.x:.1f}, y: {point.y:.1f} , date: {point.date:.} ",
            } as SeriesTooltipOptionsObject;
        }

        if (s.type === "column") {
            s.borderWidth = assetIndex === 4 && this.historyCount === 5 ? 0.5 : 1.5;
            s.borderColor = assetIndex === 4 && this.historyCount === 5 ? "#000000" : "#ffffff";
            // console.log("getSerie", year, this.plot.yearReport);
            s.tooltip = {
                headerFormat: "{series.name}",
                pointFormat: '<br>Jour: {point.x}, {point.y:.2f} ' + this._mainUnit,
            };
            s.color = {
                pattern: this.getPattern(assetIndex)
            } as PatternObject;
        }

        // deltas
        if (s.type === "column" || s.type === "line") {
            if (year === this.plot.yearReport && serieIndex < 5) {
                s.dataLabels = {
                    useHTML: false,
                    inside: false,
                    enabled: true,
                    rotation: s.type === "column" ? -90 : 0,
                    align: "left",
                    verticalAlign: "top",
                    style: {
                        color: "#000000",
                        backgroundColor: "#ffff00",
                        fontWeight: 'normal'
                    },
                    //x: 15,
                    y: -5,
                    format: "<br>{point.name:,.1f} " + this._mainUnit + ", <b>{point.custom: .2f}%</b>",
                };
            }
        }

        //if (this.plot.cumul)  console.log("getSerie() 2 >", this.plot.section, serieIndex, assetIndex);

        const debugPrefix = serieNum + "." + year + "." + serieIndex + "." + assetIndex + "." + serieGlobalIndex + " >";
        //s.name=
        // s.name = debugPrefix + this.getLegendForPlot(year, s.type, false, serieNum, serieTot);
        s.name = this.getLegendForPlot(year, s.type, false, serieNum, serieTot);

        return s;
    }

    getLegendForPlot(year, serieType, isSimulation = false, serieNum, serieTot) {
        // console.log("getLegendForPlot()", this.dataSummaryMap, year);
        if (!this.dataSummaryMap.has(year.toString())) return "";
        const isYearRef = year === this.refYear;
        const dataSummaryForYear = this.dataSummaryMap.get(year.toString());
        const unitFactor = this.plot.getUnitFactor();
        if (dataSummaryForYear.releves_count <= 0) return "-";

        let usedValKey = this.plot.valKey;
        let unit = this._mainUnit;
        if (serieNum === 2) usedValKey = usedValKey.replace("ve1", "ve2");
        let lastValue = dataSummaryForYear ? Math.round(dataSummaryForYear[usedValKey + "_last"] * unitFactor) : 0;
        let lastReleveMonthName = H.ucfirst(K.months[dataSummaryForYear.releves_count - 1]);

        if (this.plot.cumul) {
            lastReleveMonthName = '';
            lastValue = dataSummaryForYear ? Math.floor(dataSummaryForYear[usedValKey + "_cumul"] * unitFactor) : 0;
            lastValue = dataSummaryForYear.days * lastValue / 365;
        }
        let lastValueStr = H.formatNumber(lastValue, 0);
        let legend = "A reclalculer";
        let prefix = "";
        let yearToShow = year + " : ";
        if (serieTot > 1 && this.plot.isMain()) {
            if (serieNum === 1) {
                prefix = "Total";
            }
            if (serieNum === 2) {
                prefix = dataSummaryForYear.ve2Label + "";
            }
        }
        if (this.plot.plotType === "scatter") {
            unit = " ";
            prefix = " ";
            lastValueStr = " ";
            lastReleveMonthName = " ";
            yearToShow = year + " ";
        }
        if (dataSummaryForYear) {
            legend = prefix + " "
                + lastReleveMonthName + " "
                + yearToShow
                + lastValueStr + " "
                + unit;
        }
        if (isYearRef) legend = legend.trim() + " ®";
        return legend;
    }

    getSimulation(isRealData = false): SeriesOptionsType {
        let serieTitle = isRealData ? "Données réeelles" : "Simulation";
        // serieTitle += this.reportYear;
        const s = {
            animation: {
                duration: 200
            },
            id: serieTitle,
            type: 'line',
            marker: false,
            data: [],
        } as SeriesOptionsType;

        if (s.type === 'line') {
            s.lineWidth = 2;
            s.dashStyle = K.lineType[0] as DashStyleValue;
            if (isRealData) {
                if (this.plot.section === Plot.SECTION_DERNIERE)
                    s.marker = {
                        fillColor: '#ffffff',
                        lineColor: '#ff0000',
                        width: 50,
                        enabled: true,
                        lineWidth: 3,
                        symbol: 'square',
                        radius: 3
                    };
                s['tooltip'] = {
                    shared: false,
                    useHTML: true,
                    headerFormat: "Valeur réelle: {point.x: .0f}",
                    pointFormat: "<br>{point.name:,.1f} " + this._mainUnit + ", <b>{point.custom: .2f}%</b>",
                } as SeriesTooltipOptionsObject;
                s.color = this.colors[0];
                s.name = "Valeurs réelles";
                s['dataLabels'] = {
                    useHTML: true,
                    inside: false,
                    enabled: true,
                    shadow: false,
                    style: {fontWeight: "normal"},
                    format: "{point.y:,.1f}  " + this._mainUnit + " {point.custom: .2f}% " + this._mainUnit,
                };
            } else {
                s.name = "Base " + this.refYear;
                s.color = 'black';
                s['tooltip'] = {
                    shared: true,
                    useHTML: true,
                    headerFormat: "",
                    pointFormat: "Base " + this.refYear + "® : {point.y:,.1f}",

                } as SeriesTooltipOptionsObject;

            }
        }

        return s;
    }

    getSignature(index, leftOrRight, serieGlobalIndex: number, showInLegend = true) {
        const assetIndex = this.historyCount - index - 1;
        const year = this.plot.yearReport - this.historyCount + index + 1;
        let cf = {
            id: 'Reg (' + leftOrRight + ") " + this.refYear + "_" + serieGlobalIndex,
            animation: false,/*
            animation: {
                duration: 300
            },*/
            showInLegend,
            color: assetIndex === 4 ? '#000000' : this.colors[assetIndex],
            name: this.getLegendForPlot(year, 'line', false, 1, 1),
            type: 'line',
            index: serieGlobalIndex,
            lineWidth: 2,//index === (this._series.length - 1) ? 5 : 2,
            dashStyle: K.lineType[assetIndex] as DashStyleValue,
            marker: false,
            tooltip: {
                headerFormat: "x: {point.x:.1f}, y: {point.y:.1f} date:{point.custom}<br>",
                pointFormat: ""
            } as SeriesTooltipOptionsObject,
            /*     {
                 fillColor: this.colorArray[H.randomInt(0, 25)],
                 symbol: K.markers[1],
                 radius: 4
             },*/
            data: [],
        } as SeriesOptionsType;

        if (leftOrRight === 'BOTH') {
            cf['marker'] = {
                fillColor: this.colors[assetIndex],
                lineColor: '#000000',
                width: 10,
                enabled: true,
                lineWidth: index === 0 && this.historyCount === 5 ? 1 : 0,
                symbol: K.markers[assetIndex],
                radius: 6
            };
            cf['color'] = assetIndex === 4 ? '#000000' : this.colors[assetIndex];
        }
        return cf;
    }

    getPattern(index = 0, fill = null, bg = null, scale = 1): PatternOptionsObject {
        const paths = [
            "M0 0 L5 0 L5 5 L0 5 z",
            "M0 0 L5 0 L5 3 L0 3 z",
            "M0 0 L5 0 L5 2 L0 2 z",
            "M0 0 L3 0 L3 3 L0 3 z",
            "M0 5 L100 5 L-100 5 L5 5 L5 -100 L5 100 L5 5",
        ];
        const pattern = {
            color: '#000000',
            aspectRatio: 1,
            width: 5,
            height: 5,
            image: null,
            opacity: 1,
            path: null,
            x: 0,
            y: 0
        } as PatternOptionsObject;

        pattern.path = {
            d: paths[0],
            strokeWidth: 0,
            stroke: "#fff",
            r: 10,
        };
        pattern.color = this.colors[index];
        pattern.path.fill = fill ? fill : this.colors[index];
        pattern.backgroundColor = bg ? bg : '#FFFFFF';
        const rotates = ['rotate(0)', 'rotate(-45)', 'rotate(0)', 'rotate(45)', 'rotate(20)'];

        if (index === 0) {
            pattern.height = 3;
            pattern.path.d = paths[0];
            pattern.patternTransform = rotates[0];
        }
        if (index === 1) {
            pattern.height = 3;
            pattern.path.d = paths[2];
            pattern.patternTransform = 'rotate(-45)' + ",scale(1.2)";
        }
        if (index === 2) {
            pattern.height = 4;
            pattern.path.d = paths[1];
            pattern.patternTransform = rotates[index] + ",scale(1.2)";
        }
        if (index === 3) {
            pattern.height = 3;
            pattern.path.d = paths[2];
            pattern.patternTransform = rotates[index] + ",scale(1.2)";
        }
        if (index === 4) {
            pattern.x = 0;
            pattern.height = 4;
            pattern.width = 4;
            pattern.path.d = paths[3];
            pattern.patternTransform = "scale(" + scale + ")";
        }
        if (index === 5) {
            pattern.x = 0;
            pattern.height = 9;
            pattern.width = 9;
            pattern.y = 0;
            pattern.backgroundColor = "#ff0000";
            pattern.path.d = paths[4];
            pattern.path.strokeWidth = 1;
            pattern.path.stroke = "#ffffff";
            pattern.patternTransform = "scale(" + scale + ")";
        }

        //   pattern.image='https://seeklogo.com/images/B/Burberry_pattern-logo-361C603AF3-seeklogo.com.png';
        // pattern.image = "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSI0OSIgdmlld0JveD0iMCAwIDI4IDQ5Ij48ZyBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIGlkPSJoZXhhZ29ucyIgZmlsbD0iYmx1ZSIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNMTMuOTkgOS4yNWwxMyA3LjV2MTVsLTEzIDcuNUwxIDMxLjc1di0xNWwxMi45OS03LjV6TTMgMTcuOXYxMi43bDEwLjk5IDYuMzQgMTEtNi4zNVYxNy45bC0xMS02LjM0TDMgMTcuOXpNMCAxNWwxMi45OC03LjVWMGgtMnY2LjM1TDAgMTIuNjl2Mi4zem0wIDE4LjVMMTIuOTggNDF2OGgtMnYtNi44NUwwIDM1Ljgxdi0yLjN6TTE1IDB2Ny41TDI3Ljk5IDE1SDI4di0yLjMxaC0uMDFMMTcgNi4zNVYwaC0yem0wIDQ5di04bDEyLjk5LTcuNUgyOHYyLjMxaC0uMDFMMTcgNDIuMTVWNDloLTJ6Ii8+PC9nPjwvZz48L3N2Zz4=";
        // console.log("Pattern:" + index, pattern, isRef);
        return pattern;
    }

    getXAxisLabel() {
        let retVal = "";
        if (this.plot.section === Plot.SECTION_QUALITY)
            retVal = "Jours cumulés";
        if (this.plot.section === Plot.SECTION_DERNIERE)
            retVal = "Mois";
        if (this.plot.section === Plot.SECTION_BASIC) {
            if (this.plot.cumul)
                retVal = "Jours cumulés";
            else
                retVal = "Mois";
        }
        if (this.plot.section === Plot.SECTION_SIGNATURE) {
            //retVal = "Température (" + this.getWeatherStation() + ", °C)";
            retVal = "Température ( -STATION-, °C)";
        }
        return retVal;
    }

    apply(cpnt: StatmensPlotComponent = null) {
        this.chartOptions.xAxis = this._xAxis;
        this.chartOptions.yAxis = this._yAxis;
        this.chartOptions.title = this._Title;
        this.chartOptions.series = this._series;
        this.chartOptions.responsive = {rules: null} as ResponsiveOptions;
        if (cpnt) {
            cpnt.chartOptions = this.chartOptions;
            cpnt.chartUpdate('OptimiseChart', true);
        }
    }

    getChartOptions() {
        this.chartOptions.xAxis = this._xAxis;
        this.chartOptions.yAxis = this._yAxis;
        this.chartOptions.title = this._Title;
        this.chartOptions.series = this._series;
        return this.chartOptions;
    }

    applyForCassure(cpnt: StatmensCassureComponent, isCassure = true) {
        this.chartOptions.xAxis = this._xAxis;
        this.chartOptions.yAxis = this._yAxis;
        this.chartOptions.title = this._Title;
        this.chartOptions.series = this._series;
        cpnt.chartOptions = this.chartOptions;
        cpnt.chartUpdate();
    }
}

/*
 this.renderer.addPattern({
    id: 'hover-pattern',
    image: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyOCIgaGVpZ2h0PSI0OSIgdmlld0JveD0iMCAwIDI4IDQ5Ij48ZyBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxnIGlkPSJoZXhhZ29ucyIgZmlsbD0iYmx1ZSIgZmlsbC1ydWxlPSJub256ZXJvIj48cGF0aCBkPSJNMTMuOTkgOS4yNWwxMyA3LjV2MTVsLTEzIDcuNUwxIDMxLjc1di0xNWwxMi45OS03LjV6TTMgMTcuOXYxMi43bDEwLjk5IDYuMzQgMTEtNi4zNVYxNy45bC0xMS02LjM0TDMgMTcuOXpNMCAxNWwxMi45OC03LjVWMGgtMnY2LjM1TDAgMTIuNjl2Mi4zem0wIDE4LjVMMTIuOTggNDF2OGgtMnYtNi44NUwwIDM1Ljgxdi0yLjN6TTE1IDB2Ny41TDI3Ljk5IDE1SDI4di0yLjMxaC0uMDFMMTcgNi4zNVYwaC0yem0wIDQ5di04bDEyLjk5LTcuNUgyOHYyLjMxaC0uMDFMMTcgNDIuMTVWNDloLTJ6Ii8+PC9nPjwvZz48L3N2Zz4=",
    width: 30,
    height: 50
});
 */
// const ptt = this.getPattern(assetIndex);
// console.log("PATTERM", ptt);
// const b64 = Buffer.Buffer.from(JSON.stringify(ptt.path)).toString('base64');
// ptt['image'] = "data:image/svg+xml;base64," + b64;
