import {H} from "../helpers/H";
import {Observable} from "rxjs";
import {Optimise} from "../helpers/Optimise";
import {PlotPoint, PlotPointSignature} from "./PdmYearConfig";

export class PdmSignature {
    points: PlotPointSignature[];
    type: string = 'MIXTE'; //CHAUD,FROID
    flat: string = 'NONE'; //LEFT RIGHT
    sd: boolean = false;

    manual = 0;
    cassure: number = 10;

    // cassureA=cassureB if sd
    cassureA: PlotPoint;
    cassureB: PlotPoint;
    plot_data: PlotPoint[] = [];
    calc_cache = {};
    ts: number;

    constructor(points: PlotPoint[]) {
        this.points = Optimise.sortArrayByKeyVal(points, 'x');
        this.points = this.points.map(p => new PlotPoint(p) as PlotPointSignature);
        if (!this.points || this.points.length < 2) return;
        this.calc();
        this.ts = H.unixTs();
    }

    calc() {
        if (this.points.length < 9) return;
        this.plot_data = [];
        this.calc_cache = {};
        console.log("PdmSignature calc(): ", this.flat, this.type, this.points);
        for (let i = 2; i <= 8; i++) {
            const part1 = this.points.slice(0, i + 1);
            const part2 = this.points.slice(i + 1, this.points.length);
            const regLeft = new PointsReg({}).load(part1, i, 'LEFT', this.flat, this.type);
            const regRight = new PointsReg({}).load(part2, i, 'RIGHT', this.flat, this.type);
            if (this.points[i]) {
                regLeft.copyInPoint(this.points[i]);
                regRight.copyInPoint(this.points[i]);
                this.points[i].tTotal = Number(Math.pow(Math.abs(regLeft.calcTStudent()), regLeft.calcTStudentExponent()));
                this.points[i].tTotal += Number(Math.pow(Math.abs(regRight.calcTStudent()), regRight.calcTStudentExponent()));
                this.calcConnection(this.points[i], regLeft, regRight, this.points[i + 1]);
                this.calc_cache[i] = {pt: this.points[i], plot: this.plot_data};
            }
        }

        this.ts = H.unixTs();
    }

    calcConnection(pt: PlotPointSignature, regLeft: PointsReg, regRight: PointsReg, nextPoint: PlotPointSignature) {
        if (this.sd) {
            this.cassureA = Optimise.mean([pt, nextPoint]);
            this.cassureB = this.cassureA;
            pt.connexion = this.cassureA.x;
            console.log("calcConnection------: ", pt, nextPoint);
        } else {
            if (this.flat === 'LEFT') {
                pt.p1 = regLeft.meanPt.y;
                pt.p2 = 0;
            } else {
                pt.p1 = regLeft.intercept;
                pt.p2 = regLeft.slope;
            }
            if (this.flat === 'RIGHT') {
                pt.p3 = regRight.meanPt.y;
                pt.p4 = 0;
            } else {
                pt.p3 = regRight.intercept;
                pt.p4 = regRight.slope;
            }
            pt.connexion = (pt.p1 - pt.p3) / (pt.p4 - pt.p2);
             console.log(" pt.connexion: ",  pt.connexion);
        }
        // Calc ecart
        pt.ecart = Math.abs(pt.x - pt.connexion);
        /*
        if (!this.sd && pt.x < pt.connexion && nextPoint.x < pt.connexion)
            pt.ecart = 0;
        else {
            if (this.sd) pt.ecart = 1 / Math.abs(pt.y - nextPoint.y);
            else pt.ecart = Math.abs(pt.x - pt.connexion);
        }*/
        this.populatePlotData(regLeft, regRight, pt.connexion);
    }


    populatePlotData(regLeft: PointsReg, regRight: PointsReg, connection: number) {
        const lastPoint = this.points[this.points.length - 1];
        this.plot_data = [];

        let predictedY1 = regLeft.predict(this.points[0].x);
        let predictedY2 = regLeft.predict(connection);
        let predictedY3 = regRight.predict(connection);
        let predictedY4 = regRight.predict(lastPoint.x);
        const connectionAvg = (predictedY2 + predictedY3) / 2;

        if (this.flat === "LEFT") {
            predictedY1 = !this.sd ? connectionAvg : regLeft.meanPt.y;
            predictedY2 = predictedY1;
        }
        if (this.flat === "RIGHT") {
            predictedY4 = !this.sd ? connectionAvg : regRight.meanPt.y;
            predictedY3 = predictedY4;
        }

        this.plot_data.push({x: this.points[0].x, y: predictedY1, index: 1});
        this.plot_data.push({x: connection - 0.01, y: predictedY2, index: 2});
        this.plot_data.push({x: connection + 0.01, y: predictedY3, index: 3});
        /*
        if (!this.sd) {
            this.plot_data.push({x: connection - 0.01, y: connectionAvg, index: 2});
            this.plot_data.push({x: connection + 0.01, y: connectionAvg, index: 3});
        } else {

        }*/
        this.plot_data.push({x: lastPoint.x, y: predictedY4, index: 4});
        console.log("pupulatePlotData: ", this.points, this.plot_data, lastPoint);

    }

    sortBy(field: string) {
        this.points = this.points.sort((a, b) => Number(a[field]) - Number(b[field]));
    }

    getJson(): any {
        return {
            points: this.points,
            sd: this.sd,
            flat: this.flat,
            manual: this.manual,
            cassure: this.cassure,
            type: this.type,
            ts: this.ts,
        };
    }
}

export class PointsReg {
    regIndex: number;
    points: PlotPoint[];
    signature_type: string = '';
    side_reg: string = '';
    side_flat: string = '';

    meanPt: PlotPoint;
    r2: number;
    eq: number[];//0=>slope 1=>intercept retrocompatibility
    size: number;// retrocompatibility
    slope: number;
    intercept: number;
    formula: string;

    meanDev: number;

    ssyy: number;
    ssxy: number;
    ssxx: number;
    sse: number;

    s2yx: number;//S2y,x
    stdDevSlope: number;//S2y,x/SSxx

    constructor(dynProps: any = {}) {
        Object.keys(dynProps).forEach(key => this[key] = dynProps[key]);
    }

    // load used as an "afterConstructor builder"
    load(points: any[], regIndex: number, side_reg: string, side_flat: string, signature_type: string): PointsReg {
        this.regIndex = regIndex;
        this.points = points;
        this.side_reg = side_reg;
        this.side_flat = side_flat;
        this.signature_type = signature_type;
        this.size = points.length;
        if (this.size < 1) return;

        this.meanPt = Optimise.mean(this.points);
        this.meanDev = Optimise.meanDeviation(this.points, this.meanPt);

        this.ssyy = Optimise.ssyy(this.points, this.meanPt);
        this.ssxy = Optimise.ssxy(this.points, this.meanPt);
        this.ssxx = Optimise.ssxx(this.points, this.meanPt);

        this.slope = this.ssxy / this.ssxx;
        this.intercept = this.meanPt.y - this.slope * this.meanPt.x;
        this.eq = [this.slope, this.intercept];
        this.formula = "y =" + this.slope + "x + " + this.intercept;

        this.sse = Optimise.sse(this.points, this.slope, this.intercept);
        this.s2yx = this.size > 2 ? this.sse / (this.size - 2) : -1;
        this.stdDevSlope = this.s2yx / this.ssxx;
        this.r2 = (this.ssyy - this.sse) / this.ssyy;
        //console.log("CALCED Reg", this);
        return this;
    }

    calcTStudent() {
        const ecartMoy = this.meanDev;
        if (this.side_reg === 'LEFT') {
            if (this.signature_type === 'FROID' && ecartMoy === 0)
                return 0;
            else return this.slope / Math.sqrt(this.stdDevSlope);
        }
        if (this.side_reg === 'RIGHT') {
            if (this.signature_type === 'CHAUD' && ecartMoy === 0)
                return 0;
            else return this.slope / Math.sqrt(this.stdDevSlope);
        }
    }

    calcTStudentExponent() {
        if (this.side_reg === 'LEFT') {
            if (this.signature_type === 'CHAUD' || this.signature_type === 'MIXTE')
                return -1;
            else return 1;
        }
        if (this.side_reg === 'RIGHT') {
            if (this.signature_type === 'FROID' || this.signature_type === 'MIXTE')
                return -1;
            else return 1;
        }
    }

    predict(x: number): number {
        return this.slope * x + this.intercept;
    }

    copyInPoint(pt: PlotPointSignature) {
        if (this.side_reg === 'LEFT') {
            pt.leftAvg = this.meanPt.y;
            pt.leftSlope = this.slope;
            pt.leftConstant = this.intercept;
            pt.tGauche = this.calcTStudent();
        }
        if (this.side_reg === 'RIGHT') {
            pt.rightAvg = this.meanPt.y;
            pt.rightSlope = this.slope;
            pt.rightConstant = this.intercept;
            pt.tDroite = this.calcTStudent();
        }
    }

    getRegPoints(): PlotPoint[] {
        const retVal = this.points.map(pt => new PlotPoint({x: pt.x, y: this.predict(pt.x)}));
        console.log("getRegPoints()", retVal);
        return retVal;
    }
}

