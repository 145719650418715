import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

import {H} from "../../../../shared/helpers/H";
import {AppService} from "../../../../shared/services/app.service";
import {SiteService} from "../../../../shared/services/site.service";
import {FormBuilder} from "@angular/forms";
import {BaseComponent} from "../../../../shared/BaseComponent";
import {decamelize} from "@angular-devkit/core/src/utils/strings";
import {Optimise} from "../../../../shared/helpers/Optimise";
import {PdmFieldConfig, PdmRegDef, PdmRegSyncConfig} from 'app/shared/models/PdmRegDef';
import {Pdm} from "../../../../shared/models/Pdm";

@Component({
    selector: 'app-form-reg',
    template: `
        <div fxLayout="column" fxLayoutAlign="space-between stretch" class="flex-container">
            <h2 fxLayout="row" style="position: absolute;right: 10px;top: 5px;">
                <span></span>
                <diiv fxFlex></diiv>
                <mat-chip *ngIf="insertMode">
                    <mat-icon inline="true">note_add</mat-icon>
                    Mode Nouveau
                </mat-chip>
                <mat-chip *ngIf="!insertMode" color="accent">
                    <mat-icon inline="true">edit</mat-icon>
                    Mode EDITION
                </mat-chip>
            </h2>

            <div fxLayout="row" fxFlex *ngIf="tempReg">
                <div fxFlex="50">
                    <div class="p-8 pt-16 pl-4">
                        <div fxLayout="row wrap" class="">
                            <div class="full-width pb-8" *ngIf="insertMode">
                                <div class="full-width" fxLayout="row">
                                    <mat-label>Type de registre</mat-label>
                                    <div fxFlex></div>
                                    <mat-button-toggle-group fxLayout="row" class="mt-4" fxLayoutAlign="start center" [(ngModel)]="tempReg.type">
                                        <mat-button-toggle *ngFor="let t of regTypes" [value]="t"> {{ t }}</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                                <!--
                                <button mat-raised-button [color]="tempReg.type===mType?'primary':undefined"
                                        class="btn-xs-25 mr-4"
                                        (click)="configureRegFromTypes(mType,'')" *ngFor="let mType of regTypes">[{{mType}}]
                                </button>
                                -->
                            </div>
                        </div>
                        <div class="form-common">
                            <div fxLayout="row" fxLayoutAlign="space-between center">
                                <div fxFlex="33">
                                    <mat-form-field class="full-width">
                                        <input matInput [(ngModel)]="tempReg.label" placeholder="Libellé">
                                    </mat-form-field>
                                </div>
                                <div fxFlex="33">
                                    <mat-form-field class="full-width">
                                        <input matInput [(ngModel)]="tempReg.disp_order" type="number" placeholder="Ordre d'aff + CALC">
                                    </mat-form-field>
                                </div>

                                <div fxFlex="33">
                                    <mat-form-field class="full-width">
                                        <mat-label>Agent énergetique</mat-label>
                                        <mat-select placeholder="agent" [value]="tempReg.agent">
                                            <mat-option [value]="agent.key" *ngFor="let agent of myapp.k.EnergyAgents|keyvalue"> {{ agent.key }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div *ngIf="showVarExpliquee">
                                <h3>Variable expliquée</h3>
                                <div fxLayout="row wrap" class="form-section  ">
                                    <mat-checkbox fxFlex="50" [(ngModel)]="tempReg.is_derniere_cons">Var expliquée Conso</mat-checkbox>
                                    <mat-checkbox fxFlex="50" [(ngModel)]="tempReg.is_derniere_cost">Var expliquée coût</mat-checkbox>
                                    <mat-checkbox fxFlex="50" [(ngModel)]="tempReg.is_import">Direction flux:
                                        <strong *ngIf="tempReg.is_import">IMPORT</strong>
                                        <strong *ngIf="!tempReg.is_import">EXPORT</strong>
                                    </mat-checkbox>
                                </div>

                                <br>
                                <h3>Autre paramètres</h3>
                                <div fxLayout="row wrap" class="form-section ">
                                    <mat-checkbox fxFlex="33" [(ngModel)]="tempReg.is_ctot_paid">cTot Paid</mat-checkbox>
                                    <mat-checkbox fxFlex="33" [(ngModel)]="tempReg.is_econ_target">Economisable</mat-checkbox>
                                    <mat-checkbox fxFlex="33" [(ngModel)]="tempReg.is_transport">Reg. de transport</mat-checkbox>
                                </div>

                                <br>
                                <h3>Champs visibles</h3>
                                <div fxLayout="row wrap" class="form-section">
                                    <mat-checkbox fxFlex="33" [checked]="tempReg.isVisible('i')" (change)="toggleVisibleField('i')">
                                        [Index] saisi
                                    </mat-checkbox>
                                    <mat-checkbox fxFlex="33" [checked]="tempReg.isVisible('k')" (change)="toggleVisibleField('k')">
                                        [k] saisi
                                    </mat-checkbox>
                                    <mat-checkbox fxFlex="33" [checked]="tempReg.isVisible('a')" (change)="toggleVisibleField('a')">
                                        [a] saisi
                                    </mat-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div fxFlex>
                    <div class="p-8  mt-40">
                        <div class="full-width" fxLayout="row">
                            <mat-label>
                                <h3> Champ calculé</h3>
                            </mat-label>
                            <div fxFlex></div>
                            <mat-button-toggle-group class="mb-8" fxLayoutAlign="start center"
                                                     (change)="populateSyncablefields()"
                                                     [(ngModel)]="tempReg.calced_field">
                                <mat-button-toggle [value]="'d'"> Cons.</mat-button-toggle>
                                <mat-button-toggle [value]="'t'"> Tarif</mat-button-toggle>
                                <mat-button-toggle [value]="'c'"> Coût</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div class="full-width" fxLayout="row">
                            <mat-label>Type D</mat-label>
                            <div fxFlex></div>
                            <mat-button-toggle-group class="mb-8" fxLayoutAlign="start center" [(ngModel)]="tempReg.d_type">
                                <mat-button-toggle [value]="td" *ngFor="let td of typesD"> {{ td }}</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <div class="full-width" fxLayout="row">
                            <mat-label>Type de tarif</mat-label>
                            <div fxFlex></div>
                            <mat-button-toggle-group class="mb-8" fxLayoutAlign="start center"
                                                     [(ngModel)]="tempReg.t_type">
                                <mat-button-toggle [value]="'TU'"> Tarif unique</mat-button-toggle>
                                <mat-button-toggle [value]="'TA'"> Haut</mat-button-toggle>
                                <mat-button-toggle [value]="'TB'"> Bas</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                        <!--  FOREIGN TOT   -->
                        <ng-container *ngIf="showInForeignTot">
                            <div *ngIf="syncedRegsEditMode">
                                <mat-form-field class="full-width">
                                    <mat-select placeholder="Pdm étranger pour total" [value]="tempSyncConfig.uid_pdm">
                                        <mat-option (onSelectionChange)="selectForeignPdmForSyncedReg($event,pdm)"
                                                    [value]="pdm.uid"
                                                    *ngFor="let pdm of getAllPdmsForFluid">
                                            {{ pdm.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>
                        </ng-container>

                        <!--  FOREIGN REG  -->
                        <ng-container *ngIf="showInForeignReg&&allRegs">
                            <div *ngIf="syncedRegsEditMode">
                                <mat-form-field class="full-width">
                                    <mat-select placeholder="Pdm parent du rég étranger" [value]="tempSyncConfig.uid_pdm">
                                        <mat-option [value]="pdm.uid"
                                                    (onSelectionChange)="selectForeignPdmForSyncedReg($event,pdm)"
                                                    *ngFor="let pdm of getAllPdmsForFluid">{{ pdm.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="full-width">
                                    <mat-select placeholder="Num Registre étranger" [value]="tempSyncConfig.reg_num">
                                        <mat-option [value]="reg.num"
                                                    *ngFor="let reg of regsOfForeignPdm"><b>{{ reg.num }}</b>: {{ reg.label }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </ng-container>

                        <!--synced summary -->
                        <div *ngIf="showInForeignReg||showInForeignTot">
                            <mat-card class="m-0 mb-16 p-8" *ngIf="tempSyncConfig&&!syncedRegsEditMode&&!insertMode">
                                <div class="sync-summary">Informations de synchronisation</div>
                                Pdm distant: <b>{{ tempSyncConfig.pdm_label }}</b>
                                <br>Type synchro: <b>{{ tempSyncConfig.sync_type }}</b>

                                <ng-container *ngIf="showInForeignReg">
                                    <br>Reg nom: <b (dblclick)="loadAllSiteFluidRegs(false)">{{ tempSyncConfig.reg_label }}</b>
                                    <br>Reg num: <b>{{ tempSyncConfig.reg_num }}</b><br>
                                    <button *ngIf="tempSyncConfig.reg_num&&allRegs&&allRegs.length"
                                            (click)="cloneForeignRegIntoCurrent(tempSyncConfig)">Cloner
                                    </button>
                                </ng-container>

                                <ng-container *ngIf="showInForeignTot">
                                    <br>Tot nom: <b>{{ tempSyncConfig.tot_label }}</b>
                                    <br>Tot type: <b>{{ tempSyncConfig.tot_type }}</b>
                                </ng-container>

                                <mat-card-actions>
                                    <div fxFlex></div>
                                    <button mat-raised-button class="btn-xs-25" (click)="toggleSyncedRegEditMode()">Editer</button>
                                </mat-card-actions>
                            </mat-card>
                        </div>

                        <ng-container *ngIf="showFieldTypeConfigurator">
                            <br>
                            <h3>Saisie automatique</h3>
                            <div class="full-width" fxLayout="row wrap" *ngFor="let f of syncableFields">
                                <mat-label>Champ {{ f }}</mat-label>
                                <div fxFlex></div>
                                <mat-button-toggle-group class="mb-8" fxLayoutAlign="start center">
                                    <mat-button-toggle (click)="setAutoEntry(f,'xls')"> Xls2000</mat-button-toggle>
                                    <mat-button-toggle (click)="setAutoEntry(f,'eplink')"> LienEP</mat-button-toggle>
                                    <mat-button-toggle (click)="setAutoEntry(f,'macro')"> Macro</mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                            <!--field configurator  
                            <app-form-field [reg]="tempReg" [field]="'i'" (entryType)="cbEntryTypeChanged($event)"></app-form-field>
                            <app-form-field [reg]="tempReg" [field]="'d'" (entryType)="cbEntryTypeChanged($event)" (showMacrosArgs)="macroEditMode=$event"></app-form-field>
                            <app-form-field [reg]="tempReg" [field]="'t'" (showMacrosArgs)="macroEditMode=$event"></app-form-field>
                            <app-form-field [reg]="tempReg" [field]="'c'" (showMacrosArgs)="macroEditMode=$event"></app-form-field>
                            -->
                        </ng-container>
                    </div>
                </div>

                <div fxLayout="column" fxFlex="210px" *ngIf="reg&&macroEditMode!==''">
                    <div class="reg-form-holder mt-40">
                        <div class="font-weight-bold border-bottom" fxLayoutAlign="space-between center" fxLayout="row">
                            <span>Macro [{{ macroEditMode|uppercase }}]</span>
                            <div fxFlex></div>
                            <button mat-button (click)="macroEditMode=''">
                                <mat-icon inline="inline">close</mat-icon>
                            </button>
                        </div>
                        <div class="btn-add-field-macro-holder">
                            <div *ngFor="let r of regs" fxLayout="row" [title]="r.label" [ngClass]="{'current-reg':r.uid===reg.uid}">
                                <ng-container *ngFor="let f of fieldsPrefixes">
                                    <div fxFlex="20" class="btn-add-field-macro"
                                         *ngIf="r.disp_order<=tempReg.disp_order"
                                         (click)="appendMacroDef(f+r.num)"
                                         [ngClass]="{'field-included-inmacro':reg&&reg.getMacro(macroEditMode)&&reg.getMacro(macroEditMode).includes(f+r.num+' ')}">
                                        {{ f|uppercase }}{{ r.num }}
                                    </div>
                                </ng-container>
                            </div>

                        </div>
                    </div>
                </div>

            </div>

            <div fxLayout="row" class="">
                <div fxFlex>

                    <div fxFlex *ngIf="reg">
                        {{ reg.getEnergyHash() }}
                        {{ reg.getTypeHash() }}
                        {{ reg.getTariffHash() }}
                    </div>

                </div>

                <button fxFlex="150px" class="mb-16" mat-raised-button color="primary"
                        (click)="saveReg()">Sauvegarder
                </button>
            </div>
        </div>
    `,
    styleUrls: ['./form-reg.component.scss']
})
export class FormRegComponent extends BaseComponent implements AfterViewInit, OnInit, OnChanges {
    @Input('reg') reg: PdmRegDef;
    @Input('regs') regs: PdmRegDef[];
    @Input('pdm') pdm: Pdm;
    @Input('ts') ts: number;// to force update on pdm update
    @Input('snifferSelectedCol') snifferSelectedCol: string;// to force update on pdm update
    @Output('cb') cb: EventEmitter<any> = new EventEmitter<any>();

    macroEditMode = '';
    fieldsPrefixes = ['i', 'p', 'k', 'd', 'a', 't', 'c'];
    syncableFields = [];

    regTypes = PdmRegDef.REG_TYPES;
    typesD = PdmRegDef.TYPES_OF_D;

    syncedRegsEditMode = false;
    allRegs: PdmRegDef[];
    allTotsOfForeignPdm: any[];
    allTotsOfForeignPdmLabelsMap: {};

    tempReg: PdmRegDef = new PdmRegDef({});
    tempSyncConfig: PdmRegSyncConfig = new PdmRegSyncConfig();

    insertMode: boolean;

    get regsOfForeignPdm(): PdmRegDef[] {
        const regsOfForeignPdm = this.allRegs.filter(reg => reg.uid_pdm === this.tempSyncConfig.uid_pdm);
        //console.log("regsOfForeignPdm()", this.allRegs, regsOfForeignPdm, this.tempSyncConfig);
        return (this.allRegs && this.allRegs.length) ? regsOfForeignPdm : [];
        //return (this.allRegs && this.allRegs.length) ? this.allRegs : [];
    }

    get showFieldTypeConfigurator() {
        return this.tempReg && this.tempReg.type === PdmRegDef.REG_TYPE_DATA_STD;
    }

    get getAllPdmsForFluid() {
        return this.site.pdms.filter(pdm => pdm.fluid === this.site.selectedPdm.fluid && !pdm.is_vpdm && pdm.uid !== this.site.selectedPdm.uid);
    }

    /// TODO: getters useless
    get showInForeignReg() {
        return this.tempReg
            && this.tempReg.type
            && this.tempReg.type === PdmRegDef.REG_TYPE_SYNCED;
    }

    get showInForeignTot() {
        return this.tempReg
            && this.tempReg.type
            && this.tempReg.type === PdmRegDef.REG_TYPE_SYNCED;
    }

    get showVarExpliquee() {
        return this.tempReg && this.tempReg.type
            && this.tempReg.type !== PdmRegDef.REG_TYPE_VAR;
    }

    constructor(public myapp: AppService, public site: SiteService, private fb: FormBuilder) {
        super();
    }

    ngOnInit(): void {

    }

    populateSyncablefields() {
        this.syncableFields = [];
        if (this.tempReg) {
            if (this.tempReg.calced_field === 'd') {
                this.syncableFields.push('t');
                this.syncableFields.push('c');
            }
            if (this.tempReg.calced_field === 't') {
                if (!this.tempReg.isVisible('i')) {
                    this.syncableFields.push('d');
                } else
                    this.syncableFields.push('i');
                this.syncableFields.push('c');
            }
            if (this.tempReg.calced_field === 'c') {
                if (!this.tempReg.isVisible('i')) {
                    this.syncableFields.push('d');
                } else
                    this.syncableFields.push('i');
                this.syncableFields.push('t');
            }
        }
    }

    toggleVisibleField(field: string) {
        if (this.tempReg.isVisible(field))
            this.tempReg.visible_fields = this.tempReg.visible_fields.filter(item => item !== field);
        else {
            this.tempReg.visible_fields.push(field);
        }
        this.populateSyncablefields();
        console.log("toggleVisibleField", field, this.tempReg.visible_fields, this.tempReg.isVisible(field));
    }

    setAutoEntry(field: string, autoEntrySource: string) {
        console.log("setAutoEntry", field, autoEntrySource);
        this.cb.emit({arg: 'selectField', obj: field});
        if (autoEntrySource === 'xls')
            this.cb.emit({arg: 'openXlsMapper'});
    }

    toggleSyncedRegEditMode() {
        this.loadAllSiteFluidRegs();
    }

    cloneForeignRegIntoCurrent(syncConfig: PdmRegSyncConfig) {
        this.allRegs.forEach(r => {
            if (syncConfig && syncConfig.reg_num && syncConfig.reg_num === r.num
                && r.uid_pdm === syncConfig.uid_pdm) {
                const {uid, uid_pdm, num, ts_created, disp_order, label, ...data} = r;

                this.tempReg = {...this.tempReg, ...data} as PdmRegDef;
                this.tempReg.label = this.tempReg.label + " (cloned from) " + r.label;
                this.saveReg(true);
                console.log("WILL CLONE THIS:", data, " INTO: ", this.tempReg);
            }
        });
    }

    selectForeignPdmForSyncedReg = (event: any, pdm: Pdm) => {
        if (!event.isUserInput) return;
        this.tempSyncConfig = new PdmRegSyncConfig();
        console.log("selectForeignPdmForSyncedReg()", pdm, this.tempSyncConfig, this.allRegs);
        this.tempSyncConfig.uid_pdm = pdm.uid;
        this.tempSyncConfig.pdm_label = pdm.label;
        this.tempSyncConfig.pdm_prefix = pdm.pdm_prefix;

        // populate tots drop down
        /*
        if (this.reg.reg_type_sub === PdmRegDef.REG_SUB_TYPES_SYNCED_TOT) {
            let regsOfPdm = this.allRegs.filter(reg => reg.uid_pdm === this.tempSyncConfig.uid_pdm);
            regsOfPdm = this.sortArrayByKeyVal(regsOfPdm, 'disp_order');
            const totsObject = Pdm.getTotFields(regsOfPdm);
            this.allTotsOfForeignPdm = Object.keys(totsObject)
                .map(item => item.replace("dT", 'T'))
                .map(item => item.replace("cT", "T"))
                .filter((v, i, a) => a.indexOf(v) === i && v.length > 7)
                .sort();
            this.convertTotHashToHumanReadable();
            console.log("loadTotsTypesForPdm()", this.tempSyncConfig, regsOfPdm, this.allTotsOfForeignPdm);
        }*/
    };

    loadAllSiteFluidRegs(gotoEditMode = true) {
        this.allRegs = [];
        this.site.api.getAllPdmsRegsForSiteAndFluid(this.site.uid, this.pdm.fluid).subscribe(resp => {
            //console.log("getAllPdmsRegs", resp);
            resp.body.forEach(rawReg => {
                this.allRegs.push(new PdmRegDef(rawReg));
            });
            // console.log("loadAllSiteFluidRegs", this.allRegs);
            this.allRegs = Optimise.sortArrayByKeyVal(this.allRegs, 'num');
            if (gotoEditMode)
                this.syncedRegsEditMode = true;
            this.myapp.toastr.success('Registres chargées', 'Evenement!');
        });
    }

    appendMacroDef(regField: string) {

        if (!this.reg.getMacro(this.macroEditMode)) this.reg[this.macroEditMode + "_conf"].macro = regField;
        else
            this.reg[this.macroEditMode + "_conf"].macro += " + " + regField + " ";
        this.reg[this.macroEditMode + "_conf"].macro = this.reg[this.macroEditMode + "_conf"].macro.replace('  ', ' ');
    }

    saveReg(cloning = false) {
        const reg: PdmRegDef = new PdmRegDef(this.tempReg);
        Object.keys(reg).forEach(key => {
            if (key.includes("is_") && reg[key] !== undefined) {
                if (String(reg[key]) === "true") reg[key] = 1;
                if (String(reg[key]) === "false") reg[key] = 0;
            }
        });

        if (this.insertMode) {
            this.regs = this.regs.sort((a, b) => a.num - b.num);
            const lastReg = this.regs[this.regs.length - 1];
            //console.log("lastReg", lastReg);
            if (lastReg)
                reg.num = Number(lastReg.num) + 1;// simulates an autoincrement
            else reg.num = 1;
            reg.uid = this.pdm.uid + "_reg" + reg.num;
            reg.uid_pdm = this.pdm.uid;
            reg.uid_client = this.pdm.uid_client;
            reg.uid_site = this.pdm.uid_site;
            reg.fluid = this.pdm.fluid;
            reg.ts_created = H.unixTs();
            this.regs = this.regs.sort((a, b) => a.disp_order - b.disp_order);
        }
        reg.uid_client = this.pdm.uid_client;//fix bug
        reg.uid_site = this.pdm.uid_site;//fix bug
        reg.ts_updated = H.unixTs();

        if (!cloning) {
            // for synced regs
            reg.sync_config = this.tempSyncConfig;
        }


        // console.log("SaveReg", reg);
        this.site.api.savePdmReg(reg, this.insertMode).subscribe(resp => {
            //     console.log("Saved Reg", resp);
            this.tempReg = new PdmRegDef(resp.body);
            this.cb.emit({arg: 'REG', obj: this.tempReg});
        });
    }

    convertTotHashToHumanReadable() {
        const convertedArray = this.allTotsOfForeignPdm
            .map(item => decamelize(item))
            /*.map(item => item.replace("tot_exp", "Export "))
            .map(item => item.replace("tot_imp", "Import "))
            .map(item => item.replace("de_", "Energie "))
            .map(item => item.replace("dp_", "Pointe "))
            .map(item => item.replace("dr_", "Réactive "))
            .map(item => item.replace("tnn", "Tarif Normal "))
            .map(item => item.replace("thi", "Tarif HAUT "))
            .map(item => item.replace("tlo", "Tarif BAS "))*/
            .sort();
        this.allTotsOfForeignPdmLabelsMap = {};
        this.allTotsOfForeignPdm.forEach((key, idx) => this.allTotsOfForeignPdmLabelsMap[key] = convertedArray[idx]);
        console.log("convertTotHashToHumanReadable()", this.allTotsOfForeignPdm, convertedArray, this.allTotsOfForeignPdm);
    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log("FormPdmComponent", 'ngOnChanges', changes, this.pdm);
        // this.insertMode = true;
        // if (!this.reg) this.myapp.pdmEditorTabIndex = 1;
        if (changes['pdm']) {
            if (this.pdm.is_vpdm) this.regTypes = [
                'SYNCED',
            ]; else this.regTypes = PdmRegDef.REG_TYPES;
        }
        if (changes['reg']) {
            this.syncedRegsEditMode = false;
            if (this.reg && this.reg.uid) {
                this.insertMode = false;
                if (this.reg.sync_config) {
                    this.tempSyncConfig = new PdmRegSyncConfig(this.reg.sync_config);
                }
                this.tempReg = this.reg;

            } else {
                this.tempSyncConfig = new PdmRegSyncConfig();
                this.insertMode = true;
                if (this.pdm.is_vpdm)
                    this.tempReg = new PdmRegDef({type: PdmRegDef.REG_TYPE_SYNCED});
                else
                    this.tempReg = new PdmRegDef({type: PdmRegDef.REG_TYPE_DATA_STD});
            }
            /// in all cases:
            // this.checkRegType();
            this.populateSyncablefields();
        }
    }

    ngAfterViewInit(): void {

    }

    /*
    checkRegType() {
        if (this.tempReg.type === PdmRegDef.REG_TYPE_SYNCED) {
            this.subTypes = PdmRegDef.REG_SUB_TYPES_SYNCED;
        }
        if (this.tempReg.type === PdmRegDef.REG_TYPE_VAR) {
            this.subTypes = PdmRegDef.REG_SUB_TYPES_VAR;
        }
        if (this.tempReg.type === PdmRegDef.REG_TYPE_DATA_STD) {
            this.subTypes = PdmRegDef.REG_SUB_TYPES_DATA_STD;
        }
        if (this.tempReg.type === PdmRegDef.REG_TYPE_DATA_COST) {
            this.subTypes = PdmRegDef.REG_SUB_TYPES_DATA_COST;
        }
    }*/


    cbEntryTypeChanged($event) {
        const {field, value} = $event;
        console.log("cbEntryTypeChanged", field, value);
    }

}
